import { Button } from 'flowbite-react';

const TopicContainer = ({ heading, cardArray }) => {
    return (

        <section id='topicContainer' className=" p-8 flex flex-wrap md:flex-nowrap  w-full h-full justify-center items-center bg-[color:var(--bgColor)] text-[color:var(--textColor)]">
            <h3 className="text-5xl font-bold m-8">{heading}</h3>
            <div className='flex flex-col h-full justify-around gap-8'>
                {cardArray.map((item, i) => (
                    
                    <div key={item.id ?? i} className="my-1 flex flex-wrap xl:flex-nowrap  items-center bg-white border border-gray-200 rounded-lg shadow xl:flex-row xl:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 w-full">
                            <img className="hidden xl:block object-cover w-full rounded-t-lg xl:h-96 md:w-48 md:rounded-none md:rounded-s-lg" src={item.imgSrc} alt={item.imgAlt} />
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{item.title}</h5>
                                <p className="hidden md:block mb-3 font-normal text-gray-700 dark:text-gray-400">{item.desc}</p>
                                <Button className='my-2' disabled={item.noButton} onClick={() => window.open(item.link)}>{item.launchText}</Button>
                                <Button className='my-2' outline={true} disabled={item.noButton} onClick={() => window.open(item.guide)}>User Guide</Button>
                            </div>
                    </div>
                    
                ))}
            </div>
        </section>
    )
}

export default TopicContainer