import BgImgOverlay from "./components/BgImgOverlay";
import glossyIbis from './data/images/glossy-ibis.png'
import wetland1 from './data/images/dan-meyers.jpg'
import wetland2 from './data/images/ashley-inguanta.jpg'
import wetland3 from './data/images/sara-cottle.jpg'
import RutgersHeader from "./components/RutgersHeader/RutgersHeader";
import RutgersFooter from "./components/RutgersFooter/RutgersFooter";
import TopicContainer from "./components/TopicContainer";
import { issueCards, restoreCards, workCards } from "./data/cardsData";


const App = () => {


  return (
    <main className="flex-col font-sans w-screen">
      <RutgersHeader />

      <BgImgOverlay
        maxH={'20rem'}
        imgSrc={glossyIbis}
        gradientDir="br"
        gradientSize="10%"
        content={<h1 className={`text-[color:var(--textColor)] text-2xl md:text-6xl font-bold md:mt-8`}>NJ <span className="underline">Res</span>toration <span className='underline'>T</span>ool <span className="underline">Or</span>ganization <span className="underline">S</span>uite (ResTOrS)</h1>
        }
        firstColor={'#9ea900'} textColor='white' />
        <div className="bg-[#5f6a72] flex flex-col justify-center items-center text-lg">
        <p className='text-[#f2f2f2] max-w-3xl mt-4'>A diverse coalition of state agency, non-governmental organization and academic partners has been collaborating on the development of a Coastal Ecological Restoration and Adaptation Plan (CERAP) for New Jersey’s coastal marshes, estuaries and back-bays. To support this effort, this same coalition has been building the <strong>NJ Restoration Tool Organization Suite (NJResTOrS)</strong> to provide a more seamless integration of web-based decision support tools so that users can work directly from project scoping through evaluation. The NResTOrS work flow proceeds from the statewide perspective of the CERAP tool to a landscape scale evaluation of the marsh landscape with the Marsh Explorer and Living Shorelines Explorer tools to more detailed site level assessment and guidance provided by the Wetlands Assessment Tool for Condition & Health (WATCH) and the Living Shoreline Feasibility Model (LSFM).
        </p>
        <p className='text-[#f2f2f2] max-w-3xl my-4'> NJResTOrS is one component of a larger strategy to equip coastal municipal planners and non-profit partners with the resources to plan, coordinate and implement coastal restoration projects that support community resilience, ecosystem health and carbon sequestration.</p>
        </div>
      <div className="flex justify-center bg-[#9ea900] text-[#f2f2f2]">
        <h2 className="text-xl md:text-4xl font-bold m-8">NJ ResTOrS Work Flow </h2>
      </div>
      <BgImgOverlay gradientDir="br"
        gradientSize="30%" maxH={'80rem'} textColor="black" firstColor={`#ebb600`} imgSrc={wetland1} content={<TopicContainer heading={`1. Location Identification`} cardArray={workCards} />} />
      <BgImgOverlay gradientDir="br"
        gradientSize="30%" maxH={'80rem'} textColor="#f2f2f2" firstColor={`#5f6a72`} imgSrc={wetland2} content={<TopicContainer heading={`2. Issue Identification`} cardArray={issueCards} />} />
      <BgImgOverlay gradientDir="br"
        gradientSize="50%" textColor="black" firstColor={`#c1bbab`} imgSrc={wetland3} content={<TopicContainer heading={`3. Restoration Approach`} cardArray={restoreCards} />} />
      <RutgersFooter />
    </main>
  )
};

export default App;
