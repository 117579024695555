import cerap from '../data/images/cerap.png'
import marshExplorer from '../data/images/marsh-explorer.png'
import livingShorelines from '../data/images/living-shorelines.png'
import watch from '../data/images/watch.png'
import pdeLsfm from '../data/images/pde-lsfm.png'
import white from '../data/images/white.png'

//** for arrays of cards with more than one card, the title and description blocks have custom heights defined by the largest group of text */ 

export const workCards = [
    { id: 1, title: '1. Coastal Ecological Restoration and Adaptation Planning (CERAP) Explorer', link: 'https://njrestors.rutgers.edu/nj-cerap', imgAlt: 'cerap map app', imgSrc: cerap, desc: 'The NJ Coastal Ecological Restoration and Adaptation Planning (CERAP) tool’s goal is to identify areas and projects for future ecological projects that have value in increasing community resilience, ecosystem health, and carbon sequestration.', launchText: 'Launch CERAP', guide: 'https://dep.nj.gov/climatechange/resources/njcerap/' }
]
export const issueCards = [
    { id: 1, title: '2a. Marsh Explorer', link: 'https://njrestors.rutgers.edu/restoration/', imgAlt: 'marsh explorer map app', imgSrc: marshExplorer, desc: 'The Marsh Explorer includes maps of hydrological alterations and condition metrics related to marsh health and long-term status under continued sea level rise.', launchText: 'Launch Marsh Explorer', guide: 'https://njrestors.rutgers.edu/restoration/static/media/NJResTORS_guidance.0bda007450cf5cb6c092.pdf'},
    {
        id: 2, title: '2b. Wetlands Assessment Tool for Condition & Health (WATCH)', link: 'https://njrestors.rutgers.edu/pde-watch', imgAlt: 'watch app', imgSrc: watch, desc: 'The goal of WATCH is to provide a method to evaluate the condition and trajectory of a tidal wetland site to inform decision-making, restoration project prioritization, and the selection of restoration tactics.', launchText: 'Launch WATCH'
        , guide: 'https://njrestors.rutgers.edu/pde-watch/about'},
]

export const restoreCards = [
    {
        id: 1, title: '3a. Living Shorelines Explorer', link: 'https://njrestors.rutgers.edu/restoration/', imgAlt: 'living shorelines map app', imgSrc: livingShorelines, desc: 'If marsh edge erosion has been identified as an issue in the Marsh Explorer or WATCH tools, then the next step may be to employ the Living Shorelines tool to help determine the most appropriate nature-based solutions for selected locations.', launchText: 'Launch Living Shorelines'
        , guide: 'https://njrestors.rutgers.edu/restoration/static/media/NJResTORS_guidance.0bda007450cf5cb6c092.pdf'},
    {
        id: 2, noButton: true, title: '3b. Marsh Futures Mapper', imgSrc: white, imgAlt: 'placeholder', link: '/', desc: 'Landscape evaluation, single/multiple tactic evaluation, faunal considerations, and ESS intervention type.', launchText: '...future release'
    , guide: ''},
    { id: 3, title: '3c. Feasibility Model', link: 'https://njrestors.rutgers.edu/pde-lsfm/', imgAlt: 'feasibility model app', imgSrc: pdeLsfm, desc: 'The Living Shoreline Feasibility Model (LSFM) is an integrative tool that evaluates a suite of metrics to assess considerations involved in constructing and maintaining a living shoreline at a specific location.', launchText: 'Launch Feasibility Model', guide: 'https://njrestors.rutgers.edu/pde-lsfm/LSFMUserGuide+FINAL+041922.pdf'},
]