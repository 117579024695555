import React from 'react'
import headerLogo from './RUTGERS_H_WHITE.png'

const RutgersHeader = () => (
  <header className='hidden md:flex justify-center px-[3rem] py-6 bg-[#9ea900] w-full' >
    <div className='flex justify-between items-center w-screen'>
      <div className=' flex w-full items-center ml-4 text-white'>
        <button onClick={() => window.open("https://crssa.rutgers.edu/")}>
          <img
            width={150}
            height={45}
            src={headerLogo}
            alt="Rutgers Logo"
          />
        </button>
      </div>

      <nav className='hidden md:flex w-full justify-end text-white'>
        <ul className='flex justify-around w-full'>
          <li>
            <a href="https://newbrunswick.rutgers.edu/">
              Rutgers-New Brunswick
            </a>
          </li>
          <li>
            <a href="https://my.rutgers.edu">myRutgers</a>
          </li>
          <li>
            <a href="https://search.rutgers.edu/web.html">Search Rutgers</a>
          </li>
        </ul>
      </nav>
    </div>
  </header >
)

export default RutgersHeader
